.home-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative; /* Add this to position textA absolutely */
}

.image {
    width: 80%;
}

.image-caption {
    text-align: center;
    width: 80%;
    font-family: 'Inter', sans-serif;
    color: #727272;
    margin: 1vh 0 3vh;
}

@media screen and (max-width: 1600px) {
    .image-caption {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 512px) {
    .image-caption {
        font-size: 0.625rem;
    }

    .image {
        width: 100%;
    }
}